.masthead {
  position: relative;
  z-index: 0;
}

.masthead__bg {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}


.masthead .masthead-shapes {
  &__item {
    position: absolute;
    z-index: 1;
  }

  &__shape {
    &.-fixed { position: fixed; }
  
    &.-white   { background-color: #FFFFFF; }
    &.-black   { background-color: #000000; }
    &.-light-1 { background-color: #FAFAFA; }
    &.-dark-1  { background-color: var(--bg-dark-1); }
    &.-dark-2  { background-color: #17171C; }
  
    // &.-shadow-light { box-shadow: 0px 0px 60px rgba(13, 22, 52, 0.07); }
    // &.-shadow-light { box-shadow: 0px 0px 60px rgba(13, 22, 52, 0.1); }
    &.-shadow-light { box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.08); }
    &.-shadow-dark  { box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.35); }
  }
}

// home-2
.masthead .masthead-shapes.-group-1 {
  .-item-1 {
    top: -23.75vw;
    left: -28.75vw;
    
    .masthead-shapes__shape {
      @include size(64vw);
      border-radius: 100%;
    }
  }

  .-item-2 {
    bottom: -21.5625vw;
    right: -19.6875vw;

    .masthead-shapes__shape {
      @include size(68.4vw);
      border-radius: 100%;
    }
  }

  @include media-below(sm) {
    .-item-1 {
      top: 50%;
      left: -75%;
      transform: translateY(-50%);

      .masthead-shapes__shape {
        @include size(140vw);
      }
    }

    .-item-2 {
      display: none;
    }
  }
}

// home-sm-3
.masthead .masthead-shapes.-group-2 {
  .-item-1 {
    top: 10vw;
    right: 14.3vw;

    .masthead-shapes__shape {
      @include size(rem(560px));
      border-radius: 100%;
    }

    @include media-below(md) {
      top: 50%;
      right: unset;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);

      .masthead-shapes__shape {
        @include size(70vw);
      }
    }

    @include media-below(xs) {
      top: 50%;
      left: -75%;
      transform: translateY(-50%);

      .masthead-shapes__shape {
        @include size(140vw);
      }
    }
  }

  @include media-below(sm) {
    .-item-1 {
      top: 50%;
      left: -75%;
      transform: translateY(-50%);

      .masthead-shapes__shape {
        @include size(140vw);
      }
    }
  }
}

// home-sm-1, home-1
.masthead .masthead-shapes.-group-3 {
  .-item-1 {
    top: -15.0vw;
    left: -30vw;

    .masthead-shapes__shape {
      border-radius: 100%;
      @include size(78.5vw);
    }
  }

  .-item-2 {
    top: -24.25vw;
    right: -15.0625vw;

    .masthead-shapes__shape {
      border-radius: 100%;
      @include size(44.0vw);
    }
  }

  .-item-3 {
    bottom: -30.9375vw;
    right: -20.375vw;

    .masthead-shapes__shape {
      border-radius: 100%;
      @include size(46.0vw);
    }
  }

  @include media-below(sm) {
    .-item-1 {
      top: 50%;
      left: -75%;
      transform: translateY(-50%);

      .masthead-shapes__shape {
        @include size(140vw);
      }
    }

    .-item-2 { display: none; }
    .-item-3 { display: none; }
  }
}

// single-1
.masthead .masthead-shapes.-group-4 {
  .-item-1 {
    top: rem(-560px);
    left: rem(-500px);
    
    .masthead-shapes__shape {
      border-radius: 100%;
      @include size(rem(1150px));
    }
  }

  .-item-2 {
    top: rem(-300px);
    right: rem(-300px);
    
    .masthead-shapes__shape {
      border-radius: 100%;
      @include size(rem(800px));
    }
  }

  @include media-below(sm) {
    .-item-1 {
      top: 50%;
      left: -75%;
      transform: translateY(-50%);

      .masthead-shapes__shape {
        @include size(140vw);
      }
    }

    .-item-2 { display: none; }
  }
}

// single-1, single-3
.masthead .masthead-shapes.-group-5 {
  .-item-1 {
    top: rem(-520px);
    left: rem(-486px);
    
    .masthead-shapes__shape {
      border-radius: 100%;
      @include size(rem(1230px));
    }
  }

  .-item-2 {
    top: rem(-212px);
    right: rem(-300px);
    
    .masthead-shapes__shape {
      border-radius: 100%;
      @include size(rem(790px));
    }
  }

  @include media-below(sm) {
    .-item-1 {
      top: 50%;
      left: -75%;
      transform: translateY(-50%);

      .masthead-shapes__shape {
        @include size(140vw);
      }
    }

    .-item-2 { display: none; }
  }
}

.masthead .masthead-shapes.-group-6 {
  .-item-1 {
    top: 50%;
    left: 50%;
    transform: translateY(-36%) translateX(-52%);
    
    .masthead-shapes__shape {
      border-radius: 100%;
      @include size(560px);
    }
  }

  @include media-below(sm) {
    .-item-1 {
      top: 50%;
      left: -75%;
      transform: translateY(-50%);

      .masthead-shapes__shape {
        @include size(140vw);
      }
    }
  }
}
