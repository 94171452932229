/*--------------------------------------------------
	Blog pagination
---------------------------------------------------*/

.blogPost-pagination {
  display: flex;
  justify-content: center;
  padding-top: rem(20px);

  &__wrap {}


  &__wrap > * {
    font-weight: 500;
    font-size: rem(24px);
    padding: rem(10px);
    line-height: 1;
    color: var(--accent-color);
    margin-right: rem(20px);
    &:last-child { margin-right: 0; }
  }

  &__wrap a {
    &:hover {
      color: black;
    }

    &.is-active {
      color: black;
    }
  }
}
