// FONTS
// -----------------------------------------------------------------------------

// Main Font Family
//@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700,300,100);
//@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap);
//@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i&display=swap);
//@import url(https://fonts.googleapis.com/css?family=Barlow:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap);
//@import url(https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap);
//@import url(https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap);
//@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

// Fontawesome 5 Icons
//@import url(https://use.fontawesome.com/releases/v5.0.12/css/all.css);

// Google Material Icons
//@import url(https://fonts.googleapis.com/icon?family=Material+Icons);


// Theme Fonts
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
