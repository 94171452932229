.masthead.-type-1 {
  overflow: visible;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.masthead.-type-1 .masthead {
  &__content {
    position: relative;
  }

  &__title {
    line-height: 1.2;
    font-size: 6vw;

    @include media-below(lg) { font-size: 7.2vw; }
    @include media-below(md) { font-size: rem(64px); }
    @include media-below(sm) { font-size: rem(56px); }
    @include media-below(xs) { font-size: 10.5vw; }
    
    @include media-below(lg) {
      br { display: none; }
    }
  }

  &__text {
    max-width: rem(300px);
  }

  &__button {}
}