/*--------------------------------------------------
	15.3. Navigation list
---------------------------------------------------*/

.navList__wrap {
  ul {
    pointer-events: none;

    &.is-visible {
      pointer-events: auto !important;
    }
  }

	li {
    overflow: hidden;
  }

  a {
    transition: color 0.3s $easeOutQuart;

    &:hover {
      color: var(--accent-color);
    }

    &:not([href]):not([tabindex]) {
      // color: var(--menu-full-font-primary);
      &:hover { color: var(--accent-color); }
    }
  }
}

.navList {
  margin-top: rem(-8px);
  pointer-events: none;

  li a {
    cursor: pointer;
    display: inline-block;
    color: white;
    padding: 8px 0;
    @include mfont(rem(52px), 1.2, 500);

    @include media-below(md) { font-size: rem(44px); }
    @include media-below(xs) { font-size: rem(40px); }
  }

  // ul > li > a {}
}

.subnav-list {
  position: absolute;
  top: rem(-8px);

  li a {
    transform: translateY(100%);
    font-size: rem(44px);

    @include media-below(md) { font-size: rem(36px); }
    @include media-below(xs) { font-size: rem(32px); }
  }
}


.navList {
	.menu-item-has-children > a::after {
    display: inline-block;
    vertical-align: middle;
		font-family: 'icomoon' !important;
		font-style: normal;
    font-weight: normal;

    font-size: rem(14px);
    color: rgba(white, 0.4);

		transition:
			color 0.3s $easeOutQuart,
			transform 0.3s $easeOutQuart;
	}


	.menu-item-has-children > a {
		&::after {
			content: "\e900";
      margin-left: rem(20px);
		}

		&:hover::after {
			color: var(--accent-color);
			transform: translateX(10px);
		}
	}
}




@media (min-width: 1600px) {
  .nav__container {
    padding-top: rem(80px);
    padding-left: rem(120px);
    padding-right: rem(120px);
  }

  .nav-button-back .icon,
  .nav-btn-close .icon {
    @include size(4.05vw !important);
  }

  .nav__content {
    padding-top: rem(80px);
  }

  .navList li a { padding: rem(8px) 0; }
  .navList li a { font-size: 3.4vw; }
  .subnav-list li a { font-size: 2.84vw; }

  .navList {
    .menu-item-has-children > a::after {
      font-size: 0.9vw;
    }

    .menu-item-has-children > a {
      &::after {
        margin-left: 1.3vw;
      }

      &:hover::after {
        transform: translateX(0.6vw);
      }
    }
  }

  .nav__info {
    &__item {
      margin-top: 3.05vw;
      h5 { font-size: 0.9vw; }
    }

    &__content {
      a, p {
        font-size: rem(18px);
        font-size: 1.12vw;
      }
    }
  }
}
