.component-group{
  margin-top: 7rem;

  h1{
    letter-spacing: -0.02em;
    line-height: 1.1;
    font-weight: 700;
    font-size: 4.25rem;
    color: $color-brand-primary;
  }
}

.component-item{
  margin-top: 3rem;
  text-align: right;
  h3{
    display: inline-block;
    padding: 5px 15px;
    background-color: $color-white;
    color: $color-grey-darkest;
    font-size: 20px;
    font-weight: 400;
  }
  hr{
    margin-top: -17px;
    border: none;
    border-top: 1px solid $color-silver;

  }

}
