/*--------------------------------------------------
	12. Footer
---------------------------------------------------*/

.footer.-type-1 {}

.footer.-type-1 .footer {
  &__top {
    padding-top: rem(130px);
    padding-bottom: rem(110px);

    @include media-below(md) { padding: rem(80px) 0; }
  }

  &__title h2 {
    font-size: rem(46px);
    font-weight: 600;
    line-height: 1.3;

    @include media-below(md) { font-size: rem(40px); }
    @include media-below(sm) { font-size: rem(36px); }
    @include media-below(xs) { font-size: rem(28px); }
  }

  &__content {
    a, p {
      font-size: inherit;
      line-height: inherit;
    }
  }

  &__bottom {
    border-top: 1px solid rgba(black, 0.1);
    padding: rem(40px) 0;
    @include media-below(md) { padding: rem(40px) 0; }

    &.-light {
      border-top: 1px solid rgba(white, 0.1);
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    z-index: 10;
    @include mfont(rem(30px), 1, 700, null, -0.04em);

    &:hover {
      color: initial;
    }
  }

  &__copyright {
    p {
      font-size: rem(14px);
    }
  }
}
