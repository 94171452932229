.sliderMain.-type-2 {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #010101;

  .swiper-wrapper {
    transition-timing-function: $easeInOutQuart;
  }
}

.sliderMain.-type-2 .slider {
  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;

    width: 100%;
    height: 100%;
    padding-top: rem(34px);
  }

  &__subtitle__inner {
  }

  &__subtitle {
    @include mfont(18px, 1.2, 400, capitalize);
  }

  &__title {
    display: block;
    line-height: 1.1;
    letter-spacing: -0.02em;
    font-size: 5vw;

    @include media-below(md) { font-size: 12vw; }
    @include media-below(xs) { font-size: 18vw; }
  }

  &__images {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(black, 0.6);
    }
  }
}


.sliderMain.-type-2 {
  .slider__content {
    pointer-events: none;
  }

  .slider__title {
    opacity: 0.2;
    transform: scale(0.6);

    transition:
      opacity 0.6s $easeInOutQuart 0s,
      transform 0.6s $easeInOutQuart 0s;
  }

  .slider__subtitle {
    transform: translateY(100%);

    transition:
      transform 0.8s $easeOutQuart 0s,
      opacity 0.8s $easeOutQuart 0s;
  }

  .slider__img {
    opacity: 0;
    transform: scale(1.3);

    transition:
      transform 0.6s $easeInOutQuart,
      opacity 0.6s $easeInOutQuart;
    
  
    &.is-active {
      opacity: 1;
      transform: scale(1);
    }
  }
}


.sliderMain.-type-2 .swiper-slide-duplicate-active,
.sliderMain.-type-2 .swiper-slide-active {
  .slider__content {
    pointer-events: auto;
  }

  .slider__title {
    opacity: 1;
    transform: scale(1.2);
    transform: scale(1.1);

    transition:
      opacity 0.5s $easeOutQuart 0.2s,
      transform 0.5s $easeOutQuart 0.2s;
  }

  .slider__subtitle {
    transform: none;

    transition:
      transform 0.5s $easeOutQuart 0.2s,
      opacity 0.5s $easeOutQuart 0.2s;
  }
}
