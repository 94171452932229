.serviceCard {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__img {
    overflow: hidden;
  }

  &__content {
    flex: 1 1 auto;
  }

  &__icon .icon {
    // width: 64px;
    // height: 64px;
    // stroke-width: 1;
    // margin-left: -2px;
    // margin-bottom: -2px;

    // &.-left {
    //   margin-left: -2px;
    // }
  }

  &__title {}

  &__text {}

  &__navButton {
    margin-left: rem(-1px);
  }

  &__btn {}
}



// .serviceCard.-type-1.-hover {
//   transition:
//     box-shadow 350ms $easeOutQuart,
//     border 350ms $easeOutQuart;

//   border: 1px solid rgba(black, 0.1);

//   .serviceCard-icon,
//   .serviceCard-title,
//   .serviceCard-text,
//   .serviceCard-btn > a {
//     transition:
//       color 350ms $easeOutQuart,
//       transform 350ms $easeOutQuart;
//   }

//   &:hover {
//     border: 1px solid transparent;
//     box-shadow: 0px 16px 50px rgba(119, 139, 165, 0.20);

//     .serviceCard-icon,
//     .serviceCard-title {
//       color: var(--accent-color);
//     }

//     .serviceCard-text {
//       color: black !important;
//     }
//   }
// }