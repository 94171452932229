/*--------------------------------------------------
	36. 404 page
---------------------------------------------------*/

.page-404 {
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  &-content {
    padding-top: rem(40px);
  }

  &-bg {
    position: absolute;
    left: rem(-4px);
    left: -0.4vw;
    z-index: 0;

    color: rgba(0, 0, 0, 0.02);
    font-weight: 700;
    line-height: 1;
    letter-spacing: -0.03em;
    font-size: 50vw;
    
    @include media-below(sm) {
      font-size: 56vw;
      color: rgba(0, 0, 0, 0.03);
      writing-mode: vertical-rl;
    }

    &.-light {
      color: rgba(255, 255, 255, 0.02);
    }
  }

  &-title {
    font-weight: 800;
    font-size: rem(48px);
    line-height: 1;

    @include media-below(md) { font-size: rem(38px); }
    @include media-below(sm) { font-size: rem(32px); }
  }

  &-text {
    font-size: rem(20px);
    line-height: 1.6;

    @include media-below(md) { font-size: rem(17px); }
    @include media-below(sm) { font-size: rem(16px); }
  }

  &-btn {
  }
}
