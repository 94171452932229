.sliderMain.-type-1 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.sliderMain.-type-1 .sliderMain {
  &__wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &.-cover-dark::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(black, 0.7);
    }
  }

  &__content {
    position: relative;
  }

  &__subtitle {}

  &__bgTitle {
    position: absolute;
    font-size: 16vw;
    line-height: 1;
    letter-spacing: -0.02em;
    color: rgba(white, 0.03);
    text-align: center;
  }

  &__title {
    word-wrap: break-word;
    font-size: 7.6vw;

    @include media-below(lg) { font-size: 9.6vw; }
    @include media-below(md) { font-size: rem(96px); }
    @include media-below(sm) { font-size: rem(72px); }
    @include media-below(xs) { font-size: rem(50px); }
  }

  &__button {
  }
}
