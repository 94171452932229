.masthead.-type-4 {
  position: relative;
  z-index: 5;
  overflow: visible;
  width: 100%;
  height: 100vh;

  @include media-below(md) {
    height: auto !important;
  }
}

.masthead.-type-4 .masthead {
  &__img {
    @include media-below(md) {
      height: 80vh;
    }
    @include media-below(sm) {
      height: 60vh;
    }
  }

  &__img__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--bg-dark-1);
    transform-origin: right;
  }

  &__content {
    padding-top: rem(40px);

    @include media-below(md) {
      padding-top: rem(140px);
      padding-bottom: rem(100px);
    }

    @include media-below(sm) {
      padding-top: rem(120px);
      padding-bottom: rem(80px);
    }
  }

  &__subtitle {
  }

  &__title {
    line-height: 1.1;
    font-size: 4vw;

    @include media-below(md) { font-size: 5.6vw; }
    @include media-below(sm) { font-size: rem(36px); }
  }

  &__text {
  }

  &__button {
  }
}
