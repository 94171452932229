/*--------------------------------------------------
	blogSidebar
---------------------------------------------------*/

.blogSidebar {
  padding-left: rem(110px);

  @include media-below(md) {
    padding-left: 0;
    margin-top: rem(80px);
  }
}

.blogSidebar {
}

.blogSidebar .widget {
  margin-bottom: rem(40px);

  &:last-child {
    margin-bottom: 0;
  }
}


.blogSidebar {
  .widget-title {
    font-size: rem(22px);
    font-weight: 600;
    margin-bottom: rem(20px);
  }

  p, li, span {
    font-size: rem(16px);
    line-height: 1.6;
    color: rgba(black, 1.0);
  }

  ul, ol {
    ul, ol {
      padding-left: rem(8px);
      margin-top: rem(8px);
    }
  }

  li {
    margin-bottom: rem(8px);
    &:last-child { margin-bottom: 0; }
  }

  a {
    font-weight: inherit;
    word-wrap: break-word;
    line-height: inherit;
    font-size: inherit;
    color: inherit;

    transition: color 0.2s ease;

    &:hover {
      color: var(--accent-color);
    }
  }

  p {
    margin: rem(12px) 0;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  select {
    width: 100%;
  }
}
