[data-parallax] {
  position: relative;
  z-index: 0;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  [data-parallax-target] {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
