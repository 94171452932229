/*--------------------------------------------------
	Blog comments
---------------------------------------------------*/

.blogPost.comments {
  margin-bottom: 0;
  margin-top: rem(60px);
}

.blogPost.comments .blogPost__content .comments {
  &__list {
    li { list-style: none; }
		> li:first-child > .comments-item-inner { border-top: none; }
    > li:first-child { border-top: none; }
    
    .trackback,
    .pingback {
      a {
        line-height: rem(24px);
        text-decoration: none !important;
      }
    }
  }

  
	&__list {
		margin: 0;
		margin-top: rem(48px);
	}
  
	&__list > li {
    padding-bottom: rem(48px);
    margin-bottom: rem(48px);
  }
  
  &__list > li:not(:last-child) {
    border-bottom: 1px solid rgba(black, 0.2);
  }

	&__item-inner {
		display: flex;
  }
  
  &__item .children {
		padding: 0;
		margin: 0;
    padding-left: 5%;

    padding-top: rem(48px);
	}
}


.blogPost.comments .blogPost__content .comments {
  &__body {
		width: 100%;
  }
  
	&__img {
    flex: 0 0 auto;
    @include size(80px);
    max-width: rem(80px);
    margin-right: rem(22px);
    
    @include media-below(sm) {
      @include size(48px);
      max-width: rem(48px);
      margin-right: rem(16px);
    }
  }
  
	&__header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    @include media-below(sm) {
      flex-flow: column;
    }

    h4 {
      font-size: rem(20px);
      line-height: 1.3;
      font-weight: 600;
		}

		p {
			display: block;
			font-size: rem(14px);
      line-height: 1.3;
      font-weight: 400;
      color: rgba(black, 0.7);
		}
	}

	&__text {
		p {
			margin: 0;
      margin-top: rem(10px);
      font-size: rem(16px);
      letter-spacing: 0.01em;
      color: #313131;
		}

		a {
      text-decoration: underline;
    }

		ul, ul li { list-style: disc inside !important; }
		ol, ol li { list-style: decimal inside !important; }
	}

	&__reply a {
    margin-top: rem(12px);
    font-weight: 500;
    font-size: rem(14px);
    letter-spacing: 0.04em;
    line-height: 1.3;
    color: black;
	}
}
