.footer{
  position: relative;
  //border-top: 1px solid $color-silver-light;
  //background-color: $color-silver-lighter;
}

.footer{
  .footer__logo{
    svg{
      width: 60px;
      height: 40%;
      fill: $color-brand-primary;
    }
  }
}
