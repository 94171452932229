.work-grid.-col-2 {
  display: flex;
  flex-wrap: wrap;
  margin-left: rem(-50px);
  margin-right: rem(-50px);

  .grid__item {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: rem(50px);
    padding-right: rem(50px);
  }

  .grid__item:nth-child(2n + 2) {
    margin-top: rem(80px);
  }

  .grid__item:nth-child(1n + 3) {
    padding-top: 4.5%;
  }
}
