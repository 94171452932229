.masthead.-type-3 {
  overflow: visible;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.masthead.-type-3 .masthead {
  &__content {
    position: relative;

    &.-left-padding {
      .masthead__subtitle { margin-left: rem(79px); }
      .masthead__text     { margin-left: rem(77px); }
      .masthead__button   { margin-left: rem(75px); }

      @include media-below(xs) {
        .masthead__subtitle { margin-left: 0; }
        .masthead__text     { margin-left: 0; }
        .masthead__button   { margin-left: 0; }
      }
    }
  }

  &__subtitle {
  }

  &__title {
    line-height: 1.1;
    font-size: rem(96px);

    @include media-below(md) { font-size: rem(86px); }
    @include media-below(sm) { font-size: rem(70px); }
    @include media-below(xs) { font-size: rem(42px); }

    .overflow-hidden {
      padding-bottom: 0.8%;
    }
  }

  &__text {
  }

  &__button {
  }
}
