.blogCard {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__img {}

  &__content-no-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__content {
    flex: 1 1 auto;
  }

  &__info {}

  &__title {}

  &__text {}

  &__btn {}
}



.blogCard.-type-1 {}

.blogCard.-type-1.-hover {
  .blogCard__img {
    overflow: hidden;
  }

  .blogCard__img div {
    transition: transform 0.46s $easeOutQuart;
    transform: scale(1.01);
  }

  &:hover {
    .blogCard__img div {
      transform: scale(1.1);
    }
  }
}


.blogCard.-type-2 {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.blogCard.-type-2 .blogCard {
  &__content {
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(black, 0.6)
    }
  }
}

.blogCard.-type-2.-hover {
  .blogCard__img {
    transform: scale(1.1);
    // transform: scale(1.1) rotate(6deg);
    // transform: scale(1.1, 1.8) rotate(6deg);
    // transform: scale(1.8, 1.1) rotate(6deg);
    // transform: scale(1.8, 1.1) rotate(-12deg);

    transition:
      transform 0.6s $easeOutQuart,
      opacity 0.6s $easeOutQuart;
  }

  .blogCard__info,
  .blogCard__info a,
  .blogCard__title a,
  .blogCard__text,
  .blogCard__date > * {
    transition: color 0.6s $easeOutQuart 0s !important;
  }


  &:hover {
    .blogCard__img {
      opacity: 1;
      transform: scale(1.3);
    }

    .blogCard__info,
    .blogCard__info a,
    .blogCard__title a,
    .blogCard__text,
    .blogCard__date > * {
      color: white;
    }
  }
}
