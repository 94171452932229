body.preloader-visible .preloader {
  &__bg { transform: scaleY(1);  }
  &__progress { opacity: 1; }
}

.preloader {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
  pointer-events: none;
  user-select: none;

  @include size(100%);
  @include flexCenter;

  &__bg {
    pointer-events: auto;
    height: 100%;
    width: 100%;
    background-color: var(--bg-dark-4);
    box-shadow: 0 0 rem(20px) rgba(0, 0, 0, 0.12);

    will-change: transform;
    transform-origin: bottom;
    transform: scaleY(0);
  }

  &__progress {
    position: absolute;
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;

    width: rem(56px);
    height: rem(56px);
    background-color: $color-silver-light;
    opacity: 0;

    -webkit-mask-image: var(--preloader-image);
    -webkit-mask-size: contain;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;

    mask-image: var(--preloader-image);
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
  }

  &__progress__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--accent-color);
    transform-origin: bottom;
    transform: scaleY(0);
  }
}
