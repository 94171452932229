/* ------------------------------------------------------------------

  01. Variables
  02. Reveal animations
  03. Base
  04. Buttons
  05. Forms
  06. Typography
  07. 404
  08. Footers
  09. Blog masthead
  10. Blog content
  11. Blog pagination
  12. Blog comments
  13. Blog respond form
  14. Blog sidebar
  15. Blog sidebar widgets
  16. Header, menus
  17. Nav button
  18. Testimonials
  19. Video
  20. Back button
  21. Breadcrumbs
  22. Social
  23. Contact
  24. Section heading
  25. Parallax
  26. Preloader
  27. UI elements
  28. Pagination
  29. Cursor
  30. Main slider type-1
  31. Main slider type-2
  32. Main slider type-3
  33. Masthead base
  34. Masthead single portfolio project
  35. Masthead type-1
  36. Masthead type-2
  37. Masthead type-3
  38. Masthead type-4
  39. Card
  40. Price card
  41. Award
  42. Charts
  43. Clients
  44. Blog card
  45. Team
  46. Counter
  47. Portfolio
  48. Masonry
  49. Portfolio projects navigation
  50. Portfolio single project
  51. Utilities

-------------------------------------------------------------------- */

@import 'abstracts/tools';

/*--------------------------------------------------
	01. Variables
---------------------------------------------------*/

@import 'abstracts/variables';
@import 'animations/easings.scss';


/*--------------------------------------------------
	02. Reveal animations
---------------------------------------------------*/

@import 'animations/animations.scss';
@import 'animations/core.scss';


/*--------------------------------------------------
	03. Base
---------------------------------------------------*/

@import 'base/base';


/*--------------------------------------------------
	04. Buttons
---------------------------------------------------*/

@import 'base/button';


/*--------------------------------------------------
	05. Forms
---------------------------------------------------*/
@import 'base/form';


/*--------------------------------------------------
	06. Typography
---------------------------------------------------*/

@import 'base/typography';


/*--------------------------------------------------
	07. 404
---------------------------------------------------*/

@import 'layout/404.scss';


/*--------------------------------------------------
	08. Footers
---------------------------------------------------*/

@import 'layout/footer/type-1.scss';
@import 'layout/footer/type-2.scss';


/*--------------------------------------------------
	09. Blog masthead
---------------------------------------------------*/

@import 'layout/blog/masthead.scss';


/*--------------------------------------------------
	10. Blog content
---------------------------------------------------*/

@import 'layout/blog/content.scss';


/*--------------------------------------------------
	11. Blog pagination
---------------------------------------------------*/

@import 'layout/blog/pagination.scss';


/*--------------------------------------------------
	12. Blog comments
---------------------------------------------------*/

@import 'layout/blog/comments.scss';


/*--------------------------------------------------
	13. Blog respond form
---------------------------------------------------*/

@import 'layout/blog/respond_form.scss';


/*--------------------------------------------------
	14. Blog sidebar
---------------------------------------------------*/

@import 'layout/sidebar/sidebar.scss';


/*--------------------------------------------------
	15. Blog sidebar widgets
---------------------------------------------------*/

@import 'layout/sidebar/widgets.scss';


/*--------------------------------------------------
	16. Header, menus
---------------------------------------------------*/

@import 'layout/header/header.scss';
@import 'layout/header/nav-full/background.scss';
@import 'layout/header/nav-full/nav.scss';
@import 'layout/header/nav-classic/menu-header.scss';


/*--------------------------------------------------
	17. Nav button
---------------------------------------------------*/

@import 'components/navButton.scss';


/*--------------------------------------------------
	18. Testimonials
---------------------------------------------------*/

@import 'components/testimonials.scss';


/*--------------------------------------------------
	19. Video
---------------------------------------------------*/

@import 'components/video.scss';


/*--------------------------------------------------
	20. Back button
---------------------------------------------------*/

@import 'components/backButton.scss';


/*--------------------------------------------------
	21. Breadcrumbs
---------------------------------------------------*/

@import 'components/breadcrumbs.scss';


/*--------------------------------------------------
	22. Social
---------------------------------------------------*/

@import 'components/social.scss';


/*--------------------------------------------------
	23. Contact
---------------------------------------------------*/

@import 'components/contact.scss';


/*--------------------------------------------------
	24. Section heading
---------------------------------------------------*/

@import 'components/sectionHeading.scss';


/*--------------------------------------------------
	25. Parallax
---------------------------------------------------*/

@import 'components/parallax.scss';


/*--------------------------------------------------
	26. Preloader
---------------------------------------------------*/

@import 'components/preloader.scss';


/*--------------------------------------------------
	27. UI elements
---------------------------------------------------*/

@import 'components/uiElement.scss';


/*--------------------------------------------------
	28. Pagination
---------------------------------------------------*/

@import 'components/pagination.scss';


/*--------------------------------------------------
	29. Cursor
---------------------------------------------------*/

@import 'components/cursor.scss';


/*--------------------------------------------------
	30. Main slider type-1
---------------------------------------------------*/

@import 'components/header-sliders/type-1.scss';


/*--------------------------------------------------
	31. Main slider type-2
---------------------------------------------------*/

@import 'components/header-sliders/type-2.scss';


/*--------------------------------------------------
	32. Main slider type-3
---------------------------------------------------*/

@import 'components/header-sliders/type-3.scss';


/*--------------------------------------------------
	33. Masthead base
---------------------------------------------------*/

@import 'components/masthead/base.scss';


/*--------------------------------------------------
	34. Masthead single portfolio project
---------------------------------------------------*/

@import 'components/masthead/singleWork.scss';


/*--------------------------------------------------
	35. Masthead type-1
---------------------------------------------------*/

@import 'components/masthead/type-1.scss';


/*--------------------------------------------------
	36. Masthead type-2
---------------------------------------------------*/

@import 'components/masthead/type-2.scss';


/*--------------------------------------------------
	37. Masthead type-3
---------------------------------------------------*/

@import 'components/masthead/type-3.scss';


/*--------------------------------------------------
	38. Masthead type-4
---------------------------------------------------*/

@import 'components/masthead/type-4.scss';


/*--------------------------------------------------
	39. Card
---------------------------------------------------*/

@import 'components/cards/card.scss';


/*--------------------------------------------------
	40. Price card
---------------------------------------------------*/

@import 'components/cards/priceCard.scss';


/*--------------------------------------------------
	41. Award
---------------------------------------------------*/

@import 'components/cards/awwards.scss';


/*--------------------------------------------------
	42. Charts
---------------------------------------------------*/

@import 'components/cards/chart.scss';


/*--------------------------------------------------
	43. Clients
---------------------------------------------------*/

@import 'components/cards/clients.scss';


/*--------------------------------------------------
	44. Blog card
---------------------------------------------------*/

@import 'components/cards/blogCard.scss';


/*--------------------------------------------------
	45. Team
---------------------------------------------------*/

@import 'components/cards/team.scss';


/*--------------------------------------------------
	46. Counter
---------------------------------------------------*/

@import 'components/cards/counter.scss';


/*--------------------------------------------------
	47. Portfolio
---------------------------------------------------*/

@import 'components/portfolio/portfolio.scss';


/*--------------------------------------------------
	48. Masonry
---------------------------------------------------*/

@import 'components/portfolio/masonry.scss';


/*--------------------------------------------------
	49. Portfolio projects navigation
---------------------------------------------------*/

@import 'components/portfolio/projects-nav.scss';


/*--------------------------------------------------
	50. Portfolio single project
---------------------------------------------------*/

@import 'components/portfolio/single.scss';


/*--------------------------------------------------
	51. Utilities
---------------------------------------------------*/

@import 'abstracts/utilities';
@import 'abstracts/spacing';
