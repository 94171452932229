// MAIN STYLES
// -----------------------------------------------------------------------------

// FONTS
// -----------------------------------------------------------------------------
@import "fonts/fonts";

// THEME VARIABLES
// -----------------------------------------------------------------------------
@import "config/variables";

// BOOTSTRAP 4
// -----------------------------------------------------------------------------
//@import "../vendor/bootstrap/bootstrap-reboot";

// MIXINS
// -----------------------------------------------------------------------------
@import "mixins/utils";

// THEME
// -----------------------------------------------------------------------------
@import "theme/theme";

// LAYOUT
// -----------------------------------------------------------------------------
@import "layout/reset";
@import "layout/main";
@import "layout/pages";
@import "layout/navigation";
@import "layout/footer";

// COMPONENTS
// -----------------------------------------------------------------------------
@import "components/utilities";
@import "components/fixes";

// VIEWS
// -----------------------------------------------------------------------------
@import "views/home";

// DEBUGING
// -----------------------------------------------------------------------------
@import "debug/components";
