.contact-form.-type-1 {
  label {
    width: 100%;
    margin: 0;
    padding: 0;

    .form__error {
      display: none;
      color: red;
      font-size: rem(16px);
      margin-top: rem(6px);

      &.is-active {
        display: block;
      }
    }
  }

  .ajax-form-alert {
    display: none;

    &.is-active {
      display: block;
    }

    &.is-error .ajax-form-alert__content {
      background-color: rgba(red, 0.25);
    }

    &.is-success .ajax-form-alert__content {
      background-color: rgba(green, 0.2);
    }

    &__content {
      display: inline-block;
      color: black;
      padding: rem(10px) rem(16px);
      border-radius: rem(4px);

      a {
        font-weight: 600;
      }

      a:hover {
        color: black;
        text-decoration: underline;
      }
    }
  }

  input,
  textarea {
    &.-error {
      border-color: red !important;
    }
  }
}

.contact-form.-type-1 .form {}
