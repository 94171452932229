.bar-stroke-hidden {
  stroke-width: 0px !important;
}

.pieChart {
  &-chart {
    transform: rotate(-90deg);
    @include flexCenter;
  }

  circle {
    stroke-dashoffset: 0;
    fill: transparent;
    stroke-width: rem(2px);
  }

  circle.pieChart-bar {
    stroke: var(--accent-color);
    stroke-width: rem(6px);
  }

  &-percent {
    transform: rotate(90deg);
    position: absolute;
    font-family: var(--font-secondary);
    font-size: rem(28px);
    font-weight: 600;
  }


  &-title {
    font-size: rem(22px);
    line-height: rem(28px);
  }

  &-text {
  }
}


.pieChart {
  transition: background-color 0.35s $easeOutQuart;

  .pieChart-title,
  .pieChart-text,
  .pieChart-percent {
    transition: color 0.35s $easeOutQuart;
  }
}


.pieChart-light {
  circle {
    stroke: rgba(white, 0.2);
  }

  &:hover {
    background-color: white;
  
    .pieChart-percent,
    .pieChart-title {
      color: black;
    }

    .pieChart-text {
      color: var(--font-dark);
    }
  }
}


.pieChart-dark {
  circle {
    stroke: rgba(black, 0.1);
  }

  &:hover {
    background-color: black;
  
    .pieChart-percent,
    .pieChart-title {
      color: white;
    }

    .pieChart-text {
      color: var(--font-light);
    }
  }
}




.lineChart {
  &__item {
    margin-top: rem(32px);
    margin-top: rem(32px);

    &:first-child {
      margin-top: 0;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(8px);
  }

  &__content p {
    font-size: inherit;
    color: inherit;
    line-height: inherit;
  }

  &__number {}


  &-bar {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: rem(3px);
  }

  &-bar__item {
    width: 100%;
    height: 100%;

    transform-origin: 0% 50%;
    transform: scaleX(0);
  }
  
  &-bar__bg {
    position: absolute;
    width: 100%;
    height: rem(3px);
    background-color: rgba(black, 0.1);

    &.-light {
      background-color: rgba(white, 0.1);
    }
  }
}
