// GLOBAL CONFIG & VARIABLES
// -----------------------------------------------------------------------------

@import "colours";


// SIZES
// -----------------------------------------------------------------------------
$topbar-height:               70px;


// MISC
// -----------------------------------------------------------------------------
$responsive-breakpoint:       "xl";

// FONTS
// -----------------------------------------------------------------------------
//$main-font:                   "Inter";
//$main-font:                   "Roboto";
//$main-font:                   "Source Sans Pro";
//$main-font:                   "Lato";
//$main-font:                   "Montserrat";
$main-font:                     "";


// THEME COLOURS
// -----------------------------------------------------------------------------

$color-light-bg:              #f1f3f5;

// Brand Colours
//$color-brand-primary:         #f7544e;
$color-brand-primary:         #e95b33;
$color-brand-secondary:       #da3f3a;
$color-brand-light:           lighten($color-brand-primary, 6%);
$color-brand-lighter:         lighten($color-brand-primary, 12%);

// BG Colours
$color-bg-black-light:        #262D34;
$color-bg-black:              #1E252B;
$color-bg-black-dark:         #181E22;

// UI Colours
$theme-body-bg:               $color-black-dark;
$theme-topbar-bg:             $color-brand-primary;
$theme-main-color:            $color-brand-primary;
