.sectionHeading {
	&__subtitle {
    font-size: rem(14px);
    margin-bottom: rem(24px);
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: black;

    &.-light { color: white; }
	}

	&__title {}
}


.sectionHeading {
	&.-sm .sectionHeading__title {
    line-height: 1.3;
    font-weight: 700;
    letter-spacing: 0;
    font-size: rem(40px);

    @include media-below(md) { font-size: rem(38px); }
    @include media-below(sm) { font-size: rem(28px); }
  }


  &.-md .sectionHeading__title {
    line-height: 1.1;
    font-weight: 700;
    font-size: rem(56px);

    @include media-below(md) { font-size: rem(52px); }
    @include media-below(sm) { font-size: rem(48px); }
    @include media-below(xs) { font-size: rem(44px); }
	}


	&.-lg .sectionHeading__title {
    line-height: 1.1;
    font-weight: 700;
    font-size: rem(68px);

    @include media-below(md) { font-size: rem(56px); }
    @include media-below(sm) { font-size: rem(50px); }
    @include media-below(xs) { font-size: rem(48px); }
	}


	&.-xl .sectionHeading__title {
    line-height: 1.2;
    font-weight: 700;
    font-size: rem(90px);

    @include media-below(lg) { font-size: rem(76px); }
    @include media-below(md) { font-size: rem(56px); }
    @include media-below(xs) { font-size: rem(48px); }
  }
}
