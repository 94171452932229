.masthead.-type-2 {
  overflow: visible;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.masthead.-type-2 .masthead {
  &__content {
    position: relative;
    padding-top: 4.8vw;
  }

  &__title {
    line-height: 1.05;
    font-size: 7.5vw;

    @include media-below(lg) { font-size: 8.4vw; }
    @include media-below(md) { font-size: 8vw; }
    @include media-below(sm) { font-size: 10vw; }
    @include media-below(xs) { font-size: 14vw; }
    
    .overflow-hidden {
      padding-bottom: 0.9%;
    }
  }

  &__text {}

  &__button {
  }

}
