.projects-nav {
  &__item {
    overflow: hidden;
    position: relative;
    z-index: 2;
    display: block;

    &.-prev .projects-nav__content {
      padding-left: rem(120px);
      padding-right: rem(80px);
      border: 1px solid rgba(white, 0.1);

      @include media-below(sm) {
        padding-left: rem(40px);
        padding-right: rem(40px);
        align-items: flex-end;

        .projects-nav__icon {
          position: absolute;
          bottom: rem(40px);
          right: rem(36px);
        }
      }
    }

    &.-next .projects-nav__content {
      padding-left: rem(80px);
      padding-right: rem(120px);
      border: 1px solid rgba(white, 0.1);

      @include media-below(sm) {
        padding-left: rem(40px);
        padding-right: rem(40px);
        align-items: flex-start;

        .projects-nav__icon {
          position: absolute;
          bottom: rem(40px);
          left: rem(36px);
        }
      }
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    transition:
      opacity 0.4s $easeOutQuart,
      transform 2.2s $easeOutQuart;

    opacity: 0;
    transform: scale(1.2);

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#04070f, 0.7);
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: rem(220px);
    padding-bottom: rem(220px);

    @include media-below(md) {
      padding-top: rem(160px);
      padding-bottom: rem(160px);
    }

    @include media-below(sm) {
      justify-content: flex-start;
      flex-flow: column nowrap;
    }
  }

  &__icon {
  }

  &__text {
    position: absolute;
    top: rem(60px);
    &.-prev { right: rem(80px); }
    &.-next { left: rem(80px); }

    @include media-below(sm) {
      top: rem(40px);
      &.-prev { right: rem(40px); }
      &.-next { left: rem(40px); }
    }
  }
}

.projects-nav__item:hover .projects-nav__img {
  opacity: 1;
  transform: none;
}