.brand-logo{
  svg{
    height: 24px;
  }

  &.header__logo__light svg{
    fill: $color-white;
  }

  &.header__logo__dark svg{
    //fill: $color-black-darkest;
    fill: $color-brand-primary;
  }
}

.header{
  height: $header-elements-y-gap * 1.2;
  background-color: $color-white;
  border-bottom: 1px solid rgba(black, .04);
}


.current-lang{
  font-weight: 600;
}

.lang-active{
  color: $color-brand-primary;
}


.navClassic-list{

  li{
    &:hover{

    }
  }
}
