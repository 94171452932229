.masthead__content{
  .overflow-hidden{
    padding-bottom: 2px;
  }
}


.masthead__text{
  //font-size: 1.3em;
}


.js-section-slider{
  position: relative;
}


.slider-pagination-overlay{
  position: absolute;
  left: 0;
  bottom: 20px;
  right: 0;
}
.slider-arrows-centered{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  align-items: center;


  .navButton{

  }

  .js-prev{
    background-color: $color-white;
  }

  .js-next{
    margin-left: auto;
    background-color: $color-white;
  }
}
.slider-arrows-bottom{

  margin-top: 40px;
  margin-bottom: 100px;

  .navButton{ padding: 0 25%;}

  .js-prev{}

  .js-next{ margin-left: auto; }
}

.preloader__progress{ transform: scale(2); }


.slider-products{
  position: relative;


  .js-section-slider{
    overflow: hidden;
  }

  .slider-arrows-products{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    display: flex;
    align-items: center;
  }

  .js-prev{
    background-color: rgba($color-white, .5);
  }

  .js-next{
    margin-left: auto;
    background-color: rgba($color-white, .5);
  }

  .pagination{
    position: absolute;
    left: 0;
    bottom: 20px;
    right: 0;
  }

  .navButton{
    opacity: 0;
    transition: opacity .3s;
  }

  &:hover{
    .navButton{ opacity: 1; }
  }
}



.btn-download-pdf{
  margin-left: auto;
}

.btn-usecases{
  margin-left: auto;
}



.slider-home{
  .js-prev, .js-next{
    background-color: $color-white;
  }
}



.swiper-case-studies{

  .slider-slide{
    border-radius: 0.25rem;

    .card-image{
      position: relative;
      border-radius: 0.25rem;
      overflow: hidden;
      background-color: #000;

      a{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      img{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 1;
        transition: all .35s;
      }

      .card-text{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        opacity: 0;
        margin-top: 80px;
        transition: all .35s;
      }
    }

    &:hover{
      .card-image img{
        opacity: 0.3;
        transform: scale(1.2);
      }
      .card-image .card-text{
        margin-top: 0;
        opacity: 1;
      }
    }

  }

}


.product-title{
  line-height: 1;
}
