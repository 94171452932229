.size-full {
  width: 100%;
  height: 100%;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  font-family: var(--font-primary);
  color: var(--font-dark);
  line-height: 1;
  font-size: $font-size;

  @media (min-width: 1600px) { font-size: 1vw; }
  @media (min-width: 1800px) { font-size: 0.92vw; }
}

body {
  font-family: var(--font-primary);
  overflow-x: hidden;
  background-color: white;
  color: var(--font-dark);
  line-height: 1.7;
	width: 100%;
	max-width: 100%;
	padding: 0;
  font-size: var(--text-base);
}

.container {
  @media (min-width: 1260px) {
    // max-width: 100%;
    // padding-left: 12.2%;
    // padding-right: 12.2%;
    max-width: 77rem;
  }
  
  @include media-below(xs) {
    padding-right: rem(24px);
    padding-left: rem(24px);
    // margin-right: rem(24px);
    // margin-left: rem(24px);
  }
}


::selection {
  color: white;
  background-color: var(--accent-color);
  text-shadow: none;
}

.barba-container {
}

main {
  overflow-x: hidden;
}

section {
	overflow: hidden;
	position: relative;
}

a {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  transition: color 200ms ease-in-out;

	&:hover {
		text-decoration: none;
		color: var(--accent-color);
  }
}

ul, li {
	list-style: none;
	margin: 0;
	padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin: 0;
}

button {
  &:focus {
    outline: 0;
  }
}


// .entry-footer {
// 	display: inline-block !important;
// }

// .entry-footer a {
// 	display: inline-block !important;
// 	color: gray !important;

// 	&:hover {
// 		color: gray !important;
// 		text-decoration: underline !important;
// 	}
// }