.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 10;
}


.pagination__item {
  border-radius: 100%;
  width: rem(12px);
  height: rem(12px);

  margin-left: rem(10px);
  margin-right: rem(10px);

  transform: scale(0.5);
  transition:
    opacity 0.3s $easeOutCubic,
    transform 0.3s $easeOutCubic,
    color 0.3s $easeOutCubic;

  &.is-active {
    transform: scale(1);
  }
}


.pagination {
  &.-light .pagination__item {
    background-color: rgba(255, 255, 255, 0.5);
    &.is-active { background-color: rgba(255, 255, 255, 0.8); }
  }

  &.-dark .pagination__item {
    background-color: rgba(0, 0, 0, 0.5);
    &.is-active { background-color: rgba(0, 0, 0, 0.8); }
  }
}
