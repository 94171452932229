// COMPONENT: UTILITIES
// -----------------------------------------------------------------------------

.hide{ display: none; }

.text-color-brand{
  color: $color-brand-primary;
}

.align-items-center{
  align-items: center !important;
}

.text-uppercase{
  text-transform: uppercase;
}
