.clientsItem {
  position: relative;

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    @include flexCenter;
    width: 100%;
    height: 100%;
  }

  img {}

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    @include flexCenter;
    width: 100%;
    height: 100%;
    background-color: var(--bg-dark-1);
  }

  &__title {
    font-weight: 600;
  }
}


.clientsItem.-hover .clientsItem {
  &__img {
    transition:
      transform 480ms $easeInOutQuart,
      opacity 480ms $easeInOutQuart;
  }

  &__content {
    transition:
      transform 480ms $easeInOutQuart,
      opacity 480ms $easeInOutQuart;

    transform-origin: bottom;
    transform: scaleY(0);
  }

  &__title {
    transition:
      transform 350ms $easeOutQuart,
      opacity 350ms $easeOutQuart;

    opacity: 0;
    transform: translateY(16px);
  }
}

.clientsItem.-hover:hover .clientsItem {
  &__img {
    opacity: 1;
    transform: translateY(-24px);
  }

  &__content {
    opacity: 1;
    transform: none;
  }

  &__title {
    transition:
      transform 480ms $easeOutQuart 350ms,
      opacity 480ms $easeOutQuart 350ms;

    opacity: 1;
    transform: none;
  }
}


.clientsItem.-hover {
  &.-light .clientsItem__content {
    background-color: var(--bg-light-1);
  }
}







// .clients-grid {
//   display: flex;
//   // justify-content: space-between;
//   flex-wrap: wrap;
//   text-align: center;

//   .grid-item .clientsItem {
//     border: none;
//     display: unset;

//     a {
//       width: unset;
//       // width: 11rem;
//       // height: 10rem;
//     }
//   }
// }





// .clients-grid.-col-3 {
//   .grid-item {
//     flex-basis: 18%;
//   }

//   .grid-item:nth-child(3n + 2) {
//     margin-left: 23%;
//     margin-right: 23%;
//   }
// }


// .clients-grid-col-4 {
//   .grid-item {
//     flex-basis: 16%;
//   }

//   .grid-item:nth-child(4n + 2) {
//     margin-left: 12%;
//   }

//   .grid-item:nth-child(4n + 3) {
//     margin-left: 12%;
//     margin-right: 12%;
//   }

//   @include media-below(md) {
//     .grid-item {
//       flex-basis: 18%;
//     }

//     .grid-item:nth-child(4n + 2),
//     .grid-item:nth-child(4n + 3) {
//       margin-left: 0;
//       margin-right: 0;
//     }
  
//     .grid-item:nth-child(3n + 2) {
//       margin-left: 23%;
//     }
  
//     .grid-item:nth-child(3n + 3) {
//       margin-left: 23%;
//     }
//   }

//   @include media-below(md) {
//     .grid-item {
//       flex-basis: 18%;
//     }

//     .grid-item:nth-child(4n + 2),
//     .grid-item:nth-child(4n + 3) {
//       margin-left: 0;
//       margin-right: 0;
//     }
  
//     .grid-item:nth-child(3n + 2) {
//       margin-left: 23%;
//     }
  
//     .grid-item:nth-child(3n + 3) {
//       margin-left: 23%;
//     }
//   }
// }