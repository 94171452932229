.teamCard {
  position: relative;

  &__img {
  }

  &__title {
  }

  &__text {
  }

  &__social {
    display: flex;
  }

  &__social__item {
    @include flexCenter;
    font-size: rem(15px);
    margin-right: rem(28px);
    
    &:last-child {
      margin-right: 0;
    }
  }
}


.teamCard.-type-1 {
  .teamCard__content {
    position: absolute;
    top: 0;
    left: 0;

    @include flexCenter;

    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.teamCard.-type-1.-hover {
  .teamCard__content {
    transition: opacity 0.35s $easeOutQuart;
    opacity: 0;
  }

  h4,
  p,
  .teamCard__social {
    transition: transform 0.35s $easeOutQuart;
  }

  .teamCard__title  { transform: translateY(rem(20px)); }
  .teamCard__text   { transform: translateY(rem(24px)); }
  .teamCard__social { transform: translateY(rem(32px)); }


  &:hover {
    .teamCard__content {
      opacity: 1;
    }

    .teamCard__title,
    .teamCard__text,
    .teamCard__social {
      transform: none;
    }
  }
}
