.breadcrumbs a {
  font-size: inherit;
  line-height: inherit;
  color: inherit
}

.breadcrumbs {
  display: flex;

  &-item {
    display: flex;
    font-size: rem(16px);
    color: inherit;
    line-height: 1.4;
  }

  &-item::before {
    content: "/";
    color: inherit;
    font-weight: 400;
    padding: 0 rem(5px);
  }

  &-item:first-child::before {
    display: none;
  }

  .active {
    font-weight: 700;
  }
}
