$font-size: 16px;

$header-elements-y-gap: rem(48px);
$header-elements-x-gap: rem(48px);

$sm-header-elements-y-gap: rem(30px);
$sm-header-elements-x-gap: rem(30px);


:root {
  --text-xs:   #{rem(12px)};
  --text-sm:   #{rem(14px)};
  --text-base: #{rem(16px)};
  --text-lg:   #{rem(18px)};
  --text-xl:   #{rem(20px)};
  --text-2xl:  #{rem(24px)};
  --text-3xl:  #{rem(32px)};
  --text-4xl:  #{rem(40px)};
  --text-5xl:  #{rem(48px)};
  --text-6xl:  #{rem(60px)};
}

:root {
  --preloader-image: url('/assets/images/general/loader.svg');
}

$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1230px
);

// Color variables
:root {
  --accent-color: #e95b33;


  --bg-light-1: #f5f6f8;
  --bg-light-2: #F1F6F9;

  --bg-dark-1: #131419;
  --bg-dark-2: #1E2027;
  --bg-dark-3: #0D0D12;
  --bg-dark-4: #0A0A0F;

  --font-light: rgb(197, 197, 197);
  --font-dark: #454545;
}

:root {
  --font-primary: "Inter", sans-serif;
  --font-secondary: "Manrope", sans-serif;
}


// Theme variables
// :root {
//   // Navbar variables
//   --navbar-bg: 4, 7, 15;

//   // Preloader variables
//   --preloader-bg-color:  #FCFDFF;
//   --preloader-num-color: var(--gray-300);

//   // Menu fullscreen variables
//   --menu-full-bg-color:       var(--bg-dark-1);
//   --menu-full-font-primary:   white;
//   --menu-full-font-secondary: var(--font-light);

//   // Page header variables
//   --page-header-bg-color: var(--bg-dark-1);
//   --page-header-bg-image: unset;

//   // Footer variables
//   --footer-top-bg-color:    var(--bg-dark-1);
//   --footer-circle-bg-color: var(--bg-dark-2);
//   --footer-bottom-bg-color: var(--bg-dark-2);
//   --footer-bg-image:        unset;

//   // 404 variables
//   --no-page-bg-color: white;
//   --no-page-bg-image: unset;
// }
