/*--------------------------------------------------
	12. Footer
---------------------------------------------------*/

.footer.-type-2 {
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding-top: rem(120px);
  padding-bottom: rem(100px);

  @include media-below(md) {
    padding-top: rem(100px);
    padding-bottom: rem(80px);
  }

  @include media-below(sm) {
    padding-top: rem(80px);
    padding-bottom: rem(60px);
  }
}

.footer.-type-2 .footer {
  &__social {
    display: flex;
    flex-wrap: wrap;

    a {
      line-height: 1;
      font-size: rem(22px);
      margin-right: rem(40px);
      margin-top: rem(12px);

      @include media-below(sm) {
        font-size: rem(18px);
        margin-right: rem(20px);
        width: 100%;
      }
    }

    a:last-child {
      margin-right: 0;
    }
  }


  &__text {
    margin-top: rem(80px);

    @include media-below(md) {
      margin-top: rem(60px);
    }

    @include media-below(sm) {
      margin-top: rem(48px);
    }
  }

  &__link {
    word-wrap: break-word;

    @include media-below(xs) {
      font-size: 7vw;
    }
  }


  &__copyright {
    color: rgba(black, 0.6);
    margin-top: rem(100px);

    @include media-below(md) {
      margin-top: rem(80px);
    }

    @include media-below(sm) {
      margin-top: rem(48px);
    }
  }


  &__shapes {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;

    *:first-child {
      position: absolute;
      top: rem(129px);
      right: rem(-346px);

      width: rem(750px);
      height: rem(750px);
      border-radius: 100%;
      background-color: #141318;
      background-color: #FFF;
      box-shadow: 0px 4px 60px rgba(13, 22, 52, 0.08);
    }

    *:last-child {
      position: absolute;
      top: rem(398px);
      right: rem(-136px);

      width: rem(810px);
      height: rem(810px);
      border-radius: 100%;
      background-color: #FFFFFF;
      box-shadow: 0px 4px 60px rgba(13, 22, 52, 0.08);
    }
  }
}