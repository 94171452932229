.sectionVideo {
  position: relative;
  z-index: unset !important;
}

.sectionVideo__btn {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  @include flexCenter;
  @include size(rem(72px));
  border: 0;
  border-radius: 100%;

  &::after {
    content: '';
    position: absolute;

    @include size(100%);
    border-radius: 100%;
    background-color: white;
  }

  &__inner {
    @include flexCenter;
    width: 100%;
    height: 100%;
  }

  i {
    position: relative;
    z-index: 2;
  }
}

.sectionVideo__btn::after {
  animation: video_btn_pulse 2.4s infinite;
  animation-timing-function: $easeOutCubic;
}

@keyframes video_btn_pulse {
  0% {
    opacity: 0.6;
    transform: scale(1);
  }

  75% {
    opacity: 0;
    transform: scale(1.4);
  }

  100% {
    opacity: 0;
    transform: scale(1.4);
  }
}


.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
