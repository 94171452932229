.social {
  display: flex;
  z-index: 100;

  &.-bordered .social__item {
    border-radius: 100%;
    @include size(rem(44px));
    @include flexCenter;
  }

  &__item {
    position: relative;
    font-size: rem(16px);
    transition: color 0.35s $easeOutQuart;
    margin-right: rem(16px);

    &:hover {
      color: var(--accent-color);
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
