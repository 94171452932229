[data-anim-wrap] {
  pointer-events: none;
  &.animated { pointer-events: auto; }
}

[data-anim*='cover-white']::after,
[data-anim-child*='cover-white']::after {
  background-color: white;
}

[data-anim*='cover-black']::after,
[data-anim-child*='cover-black']::after {
  background-color: black;
}

[data-anim*='cover-light-1']::after,
[data-anim-child*='cover-light-1']::after {
  background-color: var(--bg-light-1);
}

[data-anim*='cover-light-2']::after,
[data-anim-child*='cover-light-2']::after {
  background-color: var(--bg-light-2);
}

[data-anim*='cover-dark-1']::after,
[data-anim-child*='cover-dark-1']::after {
  background-color: var(--bg-dark-1);
}

[data-anim*='cover-dark-2']::after,
[data-anim-child*='cover-dark-2']::after {
  background-color: var(--bg-dark-2);
}

[data-anim*='cover-accent']::after,
[data-anim-child*='cover-accent']::after {
  background-color: var(--accent-color);
}

@keyframes reveal {
  100% {
    opacity: 1;
  }
}


// Cover anim
// $cover-anim-duration: 1.4s;

// [data-anim^='cover-right'],
// [data-anim-child^='cover-right'] {
//   display: block;
//   position: relative;

//   &::after {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     z-index: 20;
//     transform: scaleX(0);
  
//     animation-duration: $cover-anim-duration;
//     animation-timing-function: $easeInOutQuart;
//   }

//   > * {
//     opacity: 0;
//     pointer-events: none;
  
//     animation-delay: $cover-anim-duration / 2;
//     animation-duration: 20ms;
//     animation-timing-function: $linear-easing;
//     animation-fill-mode: both;
//   }
// }

// [data-anim^='cover-right'].is-in-view,
// [data-anim-child^='cover-right'].is-in-view {
//   &::after {
//     animation-name: coverRight;
//   }

//   > * {
//     animation-name: reveal;
//     pointer-events: auto;
//   }
// }

// @keyframes coverRight {
//   0% {
//     transform-origin: 0 50%;
//     transform: scaleX(0);
//   }
//   42% {
//     transform-origin: 0 50%;
//     transform: scaleX(1);
//   }
//   58% {
//     transform-origin: 100% 50%;
//     transform: scaleX(1);
//   }
//   100% {
//     transform-origin: 100% 50%;
//     transform: scaleX(0);
//   }
// }



[data-anim^='img-fade'],
[data-anim-child^='img-fade'] {
  overflow: hidden;
  display: block;
  position: relative;

  > * {
    pointer-events: none;
    opacity: 0;
    transform: scale(1.5);
    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: $easeInOutQuart;
  }

  &.is-in-view > * {
    pointer-events: auto;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}



// Img anim

[data-anim^='img-right'],
[data-anim-child^='img-right'] {
  overflow: hidden;
  display: block;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: rem(-1px);
    left: rem(-1px);
    right: 0;
    z-index: 20;

    height: calc(100% + 0.125rem);
    width: calc(100% + 0.125rem);

    transform-origin: right;
    animation-duration: 0.8s;
    animation-timing-function: $easeInOutQuart;
    animation-fill-mode: both;
  }

  > * {
    transform-origin: center;
    animation-duration: 0.8s;
    animation-timing-function: $easeInOutQuart;
    animation-fill-mode: both;
  }
}

[data-anim^='img-right'].is-in-view,
[data-anim-child^='img-right'].is-in-view {
  &::after { animation-name: imgRight; }
  > * { animation-name: imgReveal; }
}

@keyframes imgRight {
  0% { transform: scaleX(1); }
  100% { transform: scaleX(0); }
}

@keyframes imgReveal {
  0% { transform: scale(1.4); }
  100% { transform: scale(1.01); }
}


// Slide anim
$slide-distance: rem(34px);
$slide-duration: 1.2s;

[data-anim^='slide-'],
[data-anim-child^='slide-'] {
  opacity: 0;
  transition-property: opacity, transform;
  pointer-events: none;

  transition-duration: $slide-duration;
  transition-timing-function: $easeOutQuart;

  &.is-in-view {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    pointer-events: auto;
  }
}

[data-anim^='slide-up'],
[data-anim-child^='slide-up'] {
  transform: translate3d(0, $slide-distance, 0);
}

[data-anim^='slide-down'],
[data-anim-child^='slide-down'] {
  transform: translate3d(0, -$slide-distance, 0);
}

[data-anim^='slide-right'],
[data-anim-child^='slide-right'] {
  transform: translate3d(-$slide-distance, 0, 0);
}

[data-anim^='slide-left'],
[data-anim-child^='slide-left'] {
  transform: translate3d($slide-distance, 0, 0);
}


// Fade animation

[data-anim^='fade'],
[data-anim-child^='fade'] {
  opacity: 0;
  transition-property: opacity;
  pointer-events: none;

  transition-duration: 0.8s;
  transition-timing-function: $easeInOutCubic;

  &.is-in-view {
    opacity: 1;
    pointer-events: auto;
  }
}






// [data-anim^='jor'],
// [data-anim-child^='jor'] {
//   transition-property: opacity, transform;
//   pointer-events: none;
  
//   opacity: 0;
//   // transform: scale(1.8, 1.1) rotate(-12deg);
//   transform-origin: top;
//   // transform: scale(1.1, 1.8) rotate(6deg) translateY(50px);
//   transform: scale(1.2, 1.5) rotate(0deg) translateY(50px);

//   transition-duration: 800ms;
//   transition-timing-function: $easeOutQuart;

//   &.is-in-view {
//     transform: none;
//     opacity: 1;
//     pointer-events: auto;
//   }
// }