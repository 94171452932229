.home-intro{
  background-color: $color-black-darker;
  width: 100%;
  height: 100vh;
  padding-top: 6rem;
  position: relative;
  overflow: hidden;

  @include media-below(md) {
    height: auto;
  }

  .container{
    height: 100%;
    position: relative;
    @include media-below(md) {
      height: auto;
    }
  }

  // HOME IMG BACKGROUND
  .home-bg-container{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @include media-below(md) {
      position: relative;
    }

    .home-bg-image{
      position: absolute;
      top: 12rem;
      right: 0;
      bottom: 6rem;
      width: 70%;
      display: flex;
      align-items: center;
      img{ width: 100%; }

      @include media-below(md) {
        position: relative;
        top: auto;
        bottom: auto;
        margin-top: 4rem;
        width: 100%;
        height: auto;
        min-height: 600px;
        border-radius: 10px;
        overflow: hidden;
      }
    }
  }

  // HEX BG SHAPES
  .home-shapes-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include media-below(md) {
      display: none;
    }

    .hex-container{
      position: absolute;
      top:  3rem;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      margin: 0;
      width: 100%;
      height: 100%;
    }

    svg{
      fill: none;
      stroke: $color-white;
      stroke-width: 2px;
      width: 100%;
      height: 100%;
    }

    .hex-1 svg{ opacity: .04; }
    .hex-2 svg{ opacity: .06; }
    .hex-3 svg{ opacity: .03; }

  }

  // SLOGAN
  .home-slogan-container{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @include media-below(md) {
      top: 13rem;
      bottom: auto;
    }

    .home-text{
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;



      h1{
        color: $color-white;
        font-weight: 700;
        font-size: 6vw;
        line-height: 1.2;
        margin-top: 6rem;
        margin-bottom: 4rem;
        @include media-below(md) {
          text-align: center;
          margin-top: 1rem;
          font-size: 30px;
        }
      }

      p{
        width: 26%;
        color: $color-silver-darker;
        font-size: 22px;
        line-height: 1.2;
        font-weight: 300;
        margin-top: 4rem;

        @include media-below(md) {
          width: 100%;
          text-align: center;
          padding-left: 10%;
          padding-right: 10%;
          color: $color-white;
          margin: 0;
        }
      }
    }
  }

  // BLOCKS LINKS
  .home-blocks-container{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @include media-below(md) {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
    }

    .home-blocks{
      position: absolute;
      top: 12rem;
      right: 0;
      bottom: 6rem;
      width: 70%;

      @include media-below(md) {
        width: 100%;
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .gm-icon{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
          width: 120px;
          fill: $color-white;
        }

        @include media-below(md) {
          position: relative;
          top: auto;
          left: auto;
          right: auto;
          bottom: auto;
          margin-top: -200px;
          margin-bottom: 140px;
        }
      }

      .hex-content{
        position: absolute;
        color: $color-white;
        text-decoration: none;
        display: block;

        @include media-below(md) {
          position: relative;
          top: auto;
          left: auto;
          right: auto;
          bottom: auto;
          width: 224px;
        }

        h6{
          position: absolute;
          left: 0;
          right: 0;
          bottom: 1.3rem;
          text-align: center;
          color: $color-white;
          font-size: 22px;
        }

        .svg-icon{
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .svg-bg svg{
          opacity: .25;
          fill: $color-white;
          @include media-below(md) {
            opacity: 1;
            fill: $color-black;
          }
        }

        &.hex-1{
          left: 3rem;
          bottom: 4rem;

          @include media-below(md) {
            position: relative;
            left: auto;
            bottom: auto;
            margin-bottom: 3rem;
          }
        }

        &.hex-2{
          top: -3rem;
          left: 50%;
          margin-left: -112px;

          @include media-below(md) {
            position: relative;
            left: auto;
            top: auto;
            margin: 0;
            margin-bottom: 3rem;
          }
        }

        &.hex-3{
          right: 3rem;
          bottom: 4rem;

          @include media-below(md) {
            position: relative;
            right: auto;
            bottom: auto;
            margin-bottom: 3rem;
          }
        }
      }
    }
  }

}

.home-img-slider.sliderMain.-type-1{
  .sliderMain__title {
    word-wrap: break-word;
    font-size: 5.6vw;
  }

  .ui-element.-bottom-right {
    bottom: 3rem;
    right: 3rem;
    left: 3rem;
    display: flex;
    justify-content: center;
  }
}

.homepage{
  .header.is-top{
    background-color: transparent;
    height: 6rem;

    .navClassic-list > li{
      color: $color-silver;
      &:hover{ color: $color-brand-primary; }
    }

    .nav-button-open{
      .icon{
        color: $color-silver;
      }
    }

    @include media-below(md) {

      background-color: darken($color-black-darker, 1%);
    }
  }
}


.solutions-detail{
  .header.is-top{
    background-color: rgba(black, .2);
    //height: 6rem;

    .navClassic-list > li{
      color: $color-silver;
      &:hover{ color: $color-brand-primary; }
    }
  }
}


.projects-list, .solutions-list{

  .img-right, .ratio{
    position: relative;
  }





  .image-link{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .3s;
    .image-text{
      opacity: 0;
      margin-top: 6rem;
      transition: all .3s ease-in-out;
      text-align: center;

      small{
        color: $color-white;
        text-align: center;
      }

      h2{
        color: $color-white;
        text-align: center;
      }


    }
  }

  .image-link:hover{
    background-color: rgba(#000, .7);
    .image-text{
      opacity: 1;
      margin-top: 0;
    }
  }
}



.gdpr{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color-black-darkest, .9);
  z-index: 999999;
  padding: 20px 40px;
  text-align: center;

  h2{
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: $color-silver-darkest;
    margin-bottom: 10px;
  }

  p{
    font-size: 13px;
    line-height: 15px;
    font-weight: 300;
    margin-bottom: 20px;
    color: $color-silver-darkest;

    a{
      color: $color-brand-primary;
    }
  }

  .btn-accept{
    padding: 0 40px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    border-radius: 4px;
    background-color: $color-brand-primary;
    color: $color-black-dark;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
  }

  .btn-close{

    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    display: block;
    text-decoration: none;
    color: $color-grey;

    &:hover{
      color: $color-white;
    }

    i{
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 20px;
    }
  }
}
