/*--------------------------------------------------
	Blog header
---------------------------------------------------*/

.masthead.-blog {
  a {
    font-weight: inherit;
    word-wrap: break-word;
    line-height: inherit;
    font-size: inherit;
    color: inherit;

    transition: color 0.2s ease;

    &:hover {
      color: var(--accent-color);
    }
  }
}

.masthead.-blog {
  .masthead {
    &__img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
    }

    &__content {
      position: relative;
      z-index: 1;
      padding-top: rem(280px);
      padding-bottom: rem(200px);

      @include media-below(md) {
        padding-top: rem(240px);
        padding-bottom: rem(120px);
      }

      @include media-below(sm) {
        padding-top: rem(200px);
        padding-bottom: rem(100px);
      }

      @include media-below(xs) {
        padding-top: rem(180px);
        padding-bottom: rem(80px);
      }
    }

    &__title {
      font-size: rem(68px);
      @include media-below(lg) { font-size: rem(62px); }
      @include media-below(md) { font-size: rem(56px); }
      @include media-below(sm) { font-size: rem(48px); }
      @include media-below(xs) { font-size: rem(36px); }
    }

    &__date {
    }

    &__tags {
    }

    &__line {
      width: rem(34px);
      height: rem(1px);
    }
  }
}