h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;
  letter-spacing: -0.02em;
  font-weight: 600;
  color: black;
}

h1 { font-size: var(--text-6xl); }
h2 { font-size: var(--text-5xl); }
h3 { font-size: var(--text-4xl); }
h4 { font-size: var(--text-3xl); }
h5 { font-size: var(--text-2xl); }
h6 { font-size: var(--text-xl); }

.text {
  &-xs   { font-size: var(--text-xs); }
  &-sm   { font-size: var(--text-sm); }
  &-base { font-size: var(--text-base); }
  &-lg   { font-size: var(--text-lg); }
  &-xl   { font-size: var(--text-xl); }
  &-2xl  { font-size: var(--text-2xl); }
  &-3xl  { font-size: var(--text-3xl); }
  &-4xl  { font-size: var(--text-4xl); }
  &-5xl  { font-size: var(--text-5xl); }
  &-6xl  { font-size: var(--text-6xl); }
}


$_typo-breakpoints: lg, md, sm, xs;

@each $break in $_typo-breakpoints {
  @include media-below($break) {
    .#{$break}\:text {
      &-xs   { font-size: var(--text-xs); }
      &-sm   { font-size: var(--text-sm); }
      &-base { font-size: var(--text-base); }
      &-lg   { font-size: var(--text-lg); }
      &-xl   { font-size: var(--text-xl); }
      &-2xl  { font-size: var(--text-2xl); }
      &-3xl  { font-size: var(--text-3xl); }
      &-4xl  { font-size: var(--text-4xl); }
      &-5xl  { font-size: var(--text-5xl); }
      &-6xl  { font-size: var(--text-6xl); }
    }
  }
}


.leading {
  &-xs  { line-height: 1.1 !important; }
  &-sm  { line-height: 1.2 !important; }
  &-md  { line-height: 1.3 !important; }
  &-lg  { line-height: 1.4 !important; }
  &-xl  { line-height: 1.5 !important; }
  &-2xl { line-height: 1.6 !important; }
  &-3xl { line-height: 1.7 !important; }
  &-4xl { line-height: 1.8 !important; }
  &-5xl { line-height: 1.9 !important; }
  &-6xl { line-height: 2 !important; }
}

.tracking {
  &-none { letter-spacing: 0 !important; }
  &-sm   { letter-spacing: 0.08em !important; }
  &-md   { letter-spacing: 0.12em !important; }
  &-lg   { letter-spacing: 0.16em !important; }
}

.number {
  font-family: var(--font-secondary);
  line-height: 1;
  letter-spacing: -0.02em;

  &.-left { margin-left: rem(-4px); }

  &.-sm { font-size: rem(60px); }
  &.-md { font-size: rem(75px); }
  &.-lg { font-size: rem(90px); }

  @include media-below(md) {
    &.-sm { font-size: rem(60px); }
    &.-md { font-size: rem(70px); }
    &.-lg { font-size: rem(84px); }
  }

  @include media-below(sm) {
    &.-sm { font-size: rem(56px); }
    &.-md { font-size: rem(64px); }
    &.-lg { font-size: rem(78px); }
  }
}